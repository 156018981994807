import { encode } from "blurhash";
import MD5 from "crypto-js/md5";
import { isAddress } from "viem";
import { twMerge } from "tailwind-merge";
import { type ClassValue, clsx } from "clsx";

export const validateEmail = (email: string) => {
  if (/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,64})+$/.test(email)) {
    return true;
  }
  return false;
};

export const shortenAccountAddress = (address: string) =>
  `${address.slice(0, 5)}....${address.slice(address.length - 5)}`;

export const removeLeadingZeros = (data: string) => data?.replace(/^[0]+/, "");

export const isValidName = (data: string) => {
  const regEx = new RegExp("^[a-zA-Z0-9]+$");
  const name = data?.replace(/\s+/g, "");
  if (regEx.test(name)) {
    return true;
  }
  return false;
};

export const validateName = (data: string) => {
  // const name = data.trim().replace(/[^a-zA-Z0-9-_\s]/g, "");
  const name = data?.replace(/(\s{2,})|(-{2,})|(_{2,})|[^a-zA-Z0-9-_\s']/g, " ").replace(/^\s*/, "");
  return name;
};

export const removeExtraWhiteSpaces = (data: string) => data?.replace(/(\s{2,})|(-{2,})|(_{2,})/g, " ");

export const removeLeadingHash = (data: string) => data?.replace(/#/g, "");

export const formatToLocalString = (data: string | number) =>
  data?.toLocaleString(undefined, { maximumFractionDigits: 2 });

export const removeSpacesAndToLowerCase = (data: string) => data?.replace(/\//g, " ").replace(/\s+/g, "").toLowerCase();

export const removeUnderScoresAndToLowerCase = (data: string) => data?.replaceAll("_", "").toLowerCase();

export const replaceUnderScoreWithSpaceAndToLowerCase = (data: string) => data?.replaceAll("_", " ").toLowerCase();

export const replaceSpaceWithUnderScoreAndToLowerCase = (data: string) => data?.replaceAll(" ", "_").toLowerCase();

export const openInNewTab = (url: string | URL) => {
  const fullURL = url.toString().includes("http") ? url : "https://" + url;

  window.open(fullURL, "_blank", "noopener,  noreferrer");
};

export const removeWhiteSpaces = (data: string) => data?.replace(/\//g, " ").replace(/\s+/g, "");

export const encodeNameToMD5String = (data: string) => MD5(data).toString();

export const verifyCoordinates = (data: string) =>
  data?.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i);

export const verifyImage = (image: File) => image.name.match(/\.(jpg|jpeg|png|gif|)$/);

export const getTokenId = (data: string) => data?.substring(data?.lastIndexOf("-") + 1);

export const isValidWalletAddress = (walletAddress: string) => {
  const result = isAddress(walletAddress);
  return result;
};

const getClampedSize = (width: number, height: number, max: number): { width: number; height: number } => {
  if (width >= height && width > max) {
    return { width: max, height: Math.round((height / width) * max) };
  }

  if (height > width && height > max) {
    return { width: Math.round((width / height) * max), height: max };
  }

  return { width, height };
};

const loadImage = async (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (...args) => {
      console.log("not loading");
      return reject(args);
    };
    img.src = src;
    img.crossOrigin = "Anonymous";
  });

const getImageData = (image: HTMLImageElement, resolutionX: number, resolutionY: number) => {
  const canvas = document.createElement("canvas");
  canvas.width = resolutionX;
  canvas.height = resolutionY;
  const context = canvas.getContext("2d");
  if (context) {
    context.drawImage(image, 0, 0, resolutionX, resolutionY);
    return context.getImageData(0, 0, resolutionX, resolutionY);
  }
  throw Error("There is no canvas context");
};

export const encodeImageToBlurhash = async (imageUrl: string) => {
  const image = await loadImage(imageUrl);
  const clampedSize = getClampedSize(image.width, image.height, 64);
  const imageData = getImageData(image, clampedSize.width, clampedSize.height);

  if (imageData) return encode(imageData.data, imageData.width, imageData.height, 4, 4);

  throw Error("There is no image data");
};

const alphanumericCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const generateRandomString = (length: number) => {
  let result = " ";
  const charactersLength = alphanumericCharacters.length;
  for (let i = 0; i < length; i++) {
    result += alphanumericCharacters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

/**
 * Used to merge Tailwindcss classes and remove duplicate classes.
 * @param {string[]} inputs - Tailwindcss classes.
 * @returns merged classes.
 */
export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));
